body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.required::after {
    content: "必須";
    background-color: #d02250;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    min-width: 10px;
    padding: 3px 7px;
    margin: 0px 5px;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    border-radius: 10px;
    display: inline-block;
}

table {
    border-color: black;
}

table th {
    background-color: #F3F3F3;
}
table td {
    background-color: white;
}

.p-postal-code {
    width: 200px;
}

.date_select_box {
    min-width: 150px
}

.logo-image {
    min-width: 10%;
    max-width: 15%;
    min-height: 10%;
    max-height: 15%;
}

.access-image {
    min-width: 50%;
    max-width: 80%;
    min-height: 50%;
    max-height: 80%;
}

.menu {
    font-size: 30px;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9); /* 背景を半透明に */
    /*color: white;*/
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* 他の要素の上に表示 */
}

.btn-primary {
    color: #ffffff;
    background-color: #23ABDD;
}

.menu-btn {
    color: #fff;
    background-color: #000000;
    font-size: 1.5rem;
    /*display: block;*/
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

.gaming {
    /* 横長で虹色を作る */
    background: linear-gradient(to right, Magenta, yellow, Cyan, Magenta) 0% center/200%;
    animation: gaming 6s linear infinite;
}
@keyframes gaming {
    /* backgroundを移動させる */
    100% { background-position-x: 200%; }
}
